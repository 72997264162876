import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  IconButton,
  TextField,
  CircularProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { postTranslationsEdit } from "../../redux/translations/action";
import { POST_TRANSLATIONS_EDIT } from "../../redux/actionTypes";

const EditTranslationKey = ({ open, onClose, editItem, onSubmit }) => {

    const settingsData = useSelector(({ settings }) => settings.settingsData);

      //Variables
  const trans = settingsData.trans;

    const editTranslationData = useSelector(
        ({ translations }) => translations.editTranslationData
      );

  const [key, setKey] = useState("");

  const dispatch = useDispatch();

  const [submiting, setSubmiting] = useState(false);


  useEffect(() => {
    if (editItem) {
      setKey(editItem.key || "");
    } else {
      setKey("");
    }
  }, [editItem]);


  useEffect(() => {
    //EDIT DATA
    if (editTranslationData != null) {
      setSubmiting(false);
      if (editTranslationData.status) {
        toast.dismiss();
        toast.success(editTranslationData.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setKey("");
        onSubmit();
        handleOnClose();
      } else {
        toast.dismiss();
        toast.error(editTranslationData.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      dispatch({ type: POST_TRANSLATIONS_EDIT, payload: null });
    }
  }, [editTranslationData]);

  const handleSubmit = () => {
    if (key.trim() === "") {
        toast.dismiss();
        toast.success("The key cannot be empty", {
          position: toast.POSITION.TOP_RIGHT,
        });
      return;
    }
    // Submit only the key as this component is simplified for key editing

    const postData = {
        key,
        locale: "en",
        universal: editItem.universal,
      pluralizations: editItem.pluralizations,
      };


   dispatch(postTranslationsEdit(postData, editItem.id));

  
  };

  const handleOnClose = () => {

    onClose();
  };

  return (
    <Dialog
      onKeyDown={(event) => {
        if (event.key === "Enter") {
          event.preventDefault();
          handleSubmit();
        }
      }}
      fullWidth
      open={open}
      onClose={handleOnClose}
    >
      <DialogTitle>
        <span>{editItem ? "Edit Translation Key" : "Add Translation Key"}</span>
        <IconButton
          onClick={handleOnClose}
          style={{ position: "absolute", right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              margin="dense"
              id="key"
              label="Translation Key"
              value={key}
              onChange={(e) => setKey(e.target.value)}
              fullWidth
              variant="standard"
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleOnClose} color="secondary">
          {trans.cancel_btn}
        </Button>
        <Button onClick={handleSubmit} color="primary" disabled={submiting}>
          {submiting ? <CircularProgress size={24} /> : trans.submit_btn}{" "}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditTranslationKey;

const environment = process.env.REACT_APP_ENV || `development`;
const isRelease = process.env.NODE_ENV == `production`;

//GENERAL VALUES
export const WEB_SITE_NAME = "Petfetti";
export const DEBUG_MODE = !isRelease;
export const DATE_FORMAT = "DD/MM/yyyy HH:mm"
export const PICKER_DATE_FORMAT = "dd/MM/yyyy HH:mm"
export const PRIMARY_COLOR = "#f05138";

//URLS
export const WEB_SITE_URL = "https://www.petfetti.com/";
export const API_URL = environment == "production" ? "https://api.petfettiapp.com" : environment == "staging" ? "https://staging.petfettiapp.com" : "http://0.0.0.0:8080/";
// export const API_URL = DEBUG_MODE ? "http://192.168.100.39:8080/" : "https://api.petfettiapp.com";
// export const API_URL = DEBUG_MODE ? "http://0.0.0.0:8080/" : "https://api.petfettiapp.com";
// export const API_URL = "https://staging.petfettiapp.com";


export const LIGHTS_MODE = "LIGHTS_MODE_VALUE";

//DATES
export const START_DATE = 'startDate';
export const END_DATE = 'endDate';

//OPTIONS
export const SLUG_OPTIONS = {
    replacement: '-',  // replace spaces with replacement character, defaults to `-`
    remove: /[*+~.()/'"!:@,]/g, // remove characters that match regex, defaults to `undefined`
    lower: true,      // convert to lower case, defaults to `false`
    strict: false,     // strip special characters except replacement, defaults to `false`
    locale: 'vi',       // language code of the locale to use
    trim: true         // trim leading and trailing replacement chars, defaults to `true`
};


export const APP_LANGS = [
    {
        'type': "enUS",
        'value': "English"
    }
];

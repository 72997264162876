import Cookies from 'js-cookie';


export const storeData = (key,value) => {
  Cookies.set(key, value)
}

export const getData = (key) => {
  try {
  return Cookies.get(key)
} catch (ex) {
  return null;
}
}


export const removeData = (key) => {
  try {
  return Cookies.remove(key)
} catch (ex) {
  return null;
}
}

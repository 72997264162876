import React, { useState,useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { DEBUG_MODE, WEB_SITE_NAME, WEB_SITE_URL } from '../../constants';
import {useDispatch, useSelector} from "react-redux";
import { postSignIn, postUserInfo } from '../../redux/auth/action';
import { toast } from 'react-toastify';
import { GoogleLogin } from '@react-oauth/google';

import { jwtDecode } from 'jwt-decode';




const SignIn = () => {

  const dispatch = useDispatch();

  //Selectors
  const settingsData = useSelector(({settings}) => settings.settingsData);
  const signInData = useSelector(({auth}) => auth.signInData);
  const signUpData = useSelector(({auth}) => auth.signUpData);
  const userInfoData = useSelector(({auth}) => auth.userInfoData);
 
  //States
  const [loading,setLoading] = useState(false);
  const [googleToken,setGoogleToken] = useState(null);
  const [givenName,setGivenName] = useState(null);
  const [familyName,setFamilyName] = useState(null);

  //Variables
  const trans = settingsData.trans;

  useEffect(() => {
      if (signInData != null) {
        window.location.reload();
      } 
  }, [signInData]);

  useEffect(() => {
    if (signUpData != null) {
      signInUser();
    } 
  }, [signUpData]);


  useEffect(() => {
    if (googleToken != null && givenName != null && familyName != null) {
      signInUser();
    } 
}, [googleToken,givenName,familyName]);

  const signInUser = () => {
    dispatch(postSignIn(googleToken,givenName,familyName));
  }


  const handleLoginData = (credentialResponse) => {
     // Decode the ID token from the credential response
    const decodedToken = jwtDecode(credentialResponse.credential);

    // Extract the given name and family name
    setGivenName(decodedToken.given_name);
    setFamilyName(decodedToken.family_name);

    const token = credentialResponse.credential;
    setGoogleToken(token);
  };

  function Copyright(props) {
    return (
      <Typography variant="body2" color="text.secondary" align="center" {...props}>
        {'Copyright © '}
        <Link color="inherit" href={WEB_SITE_URL}>
          {WEB_SITE_NAME}
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  }


  return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar style={{ height: '70px', width: '70px' }} sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon style={{ height: '30px', width: '30px' }} />
          </Avatar>
        
          <br/>
         
          <Typography component="h1" variant="h5">
            {trans.sign_in}
          </Typography>
          
          <br/>

          <p>
            {trans.sign_in_desc}
          </p>
         
          <br/>
          <Box component="form" noValidate sx={{ mt: 1 }}>
            
          <GoogleLogin
            onSuccess={credentialResponse => {
              handleLoginData(credentialResponse);
            }}
            onError={() => {
              alert(trans.login_fail_error);
            }}
          />
          </Box>

        
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}>
            <Copyright sx={{ mt: 8, mb: 4 }} />
        </Box>
       
      </Container>
  );
}

export default SignIn;
import {
  GET_SETTINGS,
  GET_CONFIGURATION
} from '../actionTypes';
  
const INIT_STATE = {
  settingsData: null,
  configurationData: null
};

const reducer = (state = INIT_STATE, action) => {
        switch(action.type) {
          case GET_SETTINGS:
                   return {
                     ...state,
                     settingsData: action.payload
                }
          case GET_CONFIGURATION:
                  return {
                    ...state,
                    configurationData: action.payload
          }     
        default:
              return state;
        }
};
      
export default reducer;
import React, { useState, useEffect, useRef } from 'react';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';

const LocalKeyField = (props) => {
    const { fieldType, part1 = '', part2 = '', fieldItemKey, onChange, autofillKey = false, disabled = false } = props;

    // Transform fieldType to lowercase and replace spaces with underscores
    const transformedFieldType = fieldType.toLowerCase().replace(/\s+/g, '_');
    
    // Ensure part2 is always lowercase
    const transformedPart2 = part2;

    // Determine initial value based on provided props
    const initialValue = fieldItemKey || `${part1}${transformedPart2 ? '.' + transformedPart2 : ''}.`;

    // State to manage the text input value
    const [inputValue, setInputValue] = useState(initialValue);

    // Ref to manage cursor position
    const inputRef = useRef(null);

    // Effect to update input value when fieldItemKey or other props change
    useEffect(() => {
        if (fieldItemKey) {
            setInputValue(fieldItemKey);
        } else if (inputValue === '') {
            // Do not reset the value if the user has cleared the input
            setInputValue('');
        } else {
            setInputValue(`${part1}${transformedPart2 ? '.' + transformedPart2 : ''}.`);
        }
    }, [fieldItemKey, part1, transformedPart2]);

    // Handler for text field changes
    const handleInputChange = (event) => {
        let newValue = event.target.value;
        const cursorPosition = event.target.selectionStart;

        // Only modify the input if there is any text in the field
        if (newValue !== '') {
            // Split the input value into parts using '.' as a delimiter
            const parts = newValue.split('.');

            // Check if autofillKey is true and whether we need to append the fieldType
            if (autofillKey && parts.length === 3 && parts[2] && !newValue.endsWith('.')) {
                // Append the transformed fieldType if it's not already appended and the last part is not transformedFieldType
                const lastPart = parts[2];
                if (!lastPart.includes(transformedFieldType)) {
                    newValue = `${newValue}.${transformedFieldType}`;
                }
            }
        }

        setInputValue(newValue);

        // Set cursor position back to where the user was typing
        if (inputRef.current) {
            const positionToSet = cursorPosition;
            setTimeout(() => {
                inputRef.current.setSelectionRange(positionToSet, positionToSet);
            }, 0);
        }

        if (onChange) {
            onChange(newValue);
        }
    };

    return (
        <Grid container>
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    id={`textfield-${fieldType}`}
                    label={`${fieldType} Key`}
                    value={inputValue}
                    onChange={handleInputChange}
                    placeholder="Enter the key"
                    inputRef={inputRef}
                    disabled={disabled}
                />
            </Grid>
        </Grid>
    );
};

export default LocalKeyField;

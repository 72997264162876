import {
  POST_TRANSLATIONS_ALL,
  POST_TRANSLATIONS_ADD,
  POST_TRANSLATIONS_EDIT,
  POST_TRANSLATIONS_DELETE
} from '../actionTypes';

import * as Store from '../../helpers/Store';
  
const INIT_STATE = {
  translationsData: null,
  addTranslationData: null,
  editTranslationData: null,
  deleteTranslationData: null,
};

const reducer = (state = INIT_STATE, action) => {
        switch(action.type) {
          case POST_TRANSLATIONS_ALL:
               return {
                ...state,
                translationsData: action.payload
          }
          case POST_TRANSLATIONS_ADD:
            return {
              ...state,
              addTranslationData: action.payload
          }
          case POST_TRANSLATIONS_EDIT:
            return {
              ...state,
              editTranslationData: action.payload
          }
          case POST_TRANSLATIONS_DELETE:
            return {
              ...state,
              deleteTranslationData: action.payload
          }
        default:
              return state;
        }
};
      
export default reducer;
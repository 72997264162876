import axios from 'axios';
import { API_URL, DEBUG_MODE } from '../constants';

export default axios.create({
  baseURL: API_URL,
  timeout: 5000 * 10,
  headers: {
    'X-Platform': 'webAdmin',
    'Content-Type': 'application/json'
  }
});


export const handleApiResponse = (response) => {
  if (DEBUG_MODE){
    console.log(response); 
  }
  if (response.status === 200 || response.status === 201) {
    return { status: true, message: 'Success', status_code: response.status };
  } else if (response.status === 204) {
    return { status: true, message: 'No Content', status_code: response.status };
  } else if (response.status >= 400 && response.status < 500) {
    return { status: false, message: 'Operation Failed', status_code: response.status };
  } else if (response.status >= 500 && response.status < 600) {
    return { status: false, message: 'Operation Failed', status_code: response.status };
  } else {
    // Handle other cases if needed
    return { status: false, message: response.reason != null ? response.reason :  'Unexpected status code', status_code: response.status };
  }
};
import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  IconButton,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  CircularProgress,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-toastify";
import {
  postTranslationsAdd,
  postTranslationsEdit,
} from "../../redux/translations/action";
import {
  POST_TRANSLATIONS_ADD,
  POST_TRANSLATIONS_EDIT,
} from "../../redux/actionTypes";

const AddTranslationDialog = ({
  open,
  onClose,
  editItemEn,
  editItem,
  onSubmit,
}) => {
  const dispatch = useDispatch();
  const configurationData = useSelector(
    ({ settings }) => settings.configurationData
  );
  const addTranslationData = useSelector(
    ({ translations }) => translations.addTranslationData
  );
  const editTranslationData = useSelector(
    ({ translations }) => translations.editTranslationData
  );
  const settingsData = useSelector(({ settings }) => settings.settingsData);

  const [translations, setTranslations] = useState({});
  const [isPlural, setIsPlural] = useState(false);
  const [key, setKey] = useState("");
  const [localeType, setLocaleType] = useState("en");
  const [isEnEmpty, setIsEnEmpty] = useState(false);
  const [submiting, setSubmiting] = useState(false);

  //Variables
  const trans = settingsData.trans;

  useEffect(() => {
    if (editItem) {
      setLocaleType(editItem.locale);
      setIsEnEmpty(
        editItemEn.pluralizations == null && editItemEn.universal === null
      );

      let updatedTranslations = {};
      if (editItemEn.pluralizations && editItemEn.locale) {
        updatedTranslations[editItemEn.locale] = editItemEn.pluralizations;
        setIsPlural(true);
      }
      if (editItemEn.universal !== undefined) {
        updatedTranslations[editItemEn.locale] = {
          ...updatedTranslations[editItemEn.locale],
          singular: editItemEn.universal,
        };
      }
      if (editItem.pluralizations && editItem.locale) {
        updatedTranslations[editItem.locale] = editItem.pluralizations;
        setIsPlural(true);
      }
      if (editItem.universal !== undefined) {
        updatedTranslations[editItem.locale] = {
          ...updatedTranslations[editItem.locale],
          singular: editItem.universal,
        };
      }

      setTranslations(updatedTranslations);
      setKey(editItem.key || "");
    } else {
      setKey("");
      const initialTranslations = { en: { singular: "", plural: "" } };
      configurationData.supportedLocalizations
        .find((loc) => loc.locale === "en")
        .pluralizations.forEach((plural) => {
          initialTranslations.en[plural] = "";
        });
      setTranslations(initialTranslations);
    }
  }, [editItem, configurationData]);



  useEffect(() => {
    //ADD DATA
    if (addTranslationData != null) {
      setSubmiting(false);
      if (addTranslationData.status) {
        toast.dismiss();
        toast.success(addTranslationData.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        onSubmit();
        clearData();
        onClose();
      } else {
        toast.dismiss();
        toast.error(addTranslationData.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      dispatch({ type: POST_TRANSLATIONS_ADD, payload: null });
    }
  }, [addTranslationData]);

  useEffect(() => {
    //EDIT DATA
    if (editTranslationData != null) {
      setSubmiting(false);
      if (editTranslationData.status) {
        toast.dismiss();
        toast.success(editTranslationData.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        onSubmit();
        clearData();
        onClose();
      } else {
        toast.dismiss();
        toast.error(editTranslationData.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      dispatch({ type: POST_TRANSLATIONS_EDIT, payload: null });
    }
  }, [editTranslationData]);

  const handleTranslationChange = (locale, value, pluralizationType) => {
    setTranslations((prevTranslations) => ({
      ...prevTranslations,
      [locale]: { ...prevTranslations[locale], [pluralizationType]: value },
    }));
  };

  const generateTextFields = () => {
    const localesToShow = editItem ? ["en", localeType] : ["en"];
    const localizations = configurationData.supportedLocalizations.filter(
      (loc) => localesToShow.includes(loc.locale)
    );

    if (!editItem) {
      return isPlural ? (
        localizations
          .find((loc) => loc.locale === "en")
          .pluralizations.map((form) => (
            <Grid item xs={12} key={`en-${form}`}>
              <TextField
                id={`en-${form}`}
                label={`English (${form})`}
                value={translations.en?.[form] || ""}
                onChange={(e) =>
                  handleTranslationChange("en", e.target.value, form)
                }
                fullWidth
                variant="standard"
              />
            </Grid>
          ))
      ) : (
        <Grid item xs={12}>
          <TextField
            id="en-singular"
            label="English (singular)"
            value={translations.en?.singular || ""}
            onChange={(e) =>
              handleTranslationChange("en", e.target.value, "singular")
            }
            fullWidth
            variant="standard"
          />
        </Grid>
      );
    } else {
      return localizations.map((loc) =>
        isPlural ? (
          loc.pluralizations.map((form) => (
            <Grid item xs={12} key={`${loc.locale}-${form}`}>
              <TextField
                id={`${loc.locale}-${form}`}
                label={`${loc.locale} (${form})`}
                value={translations[loc.locale]?.[form] || ""}
                onChange={(e) =>
                  handleTranslationChange(loc.locale, e.target.value, form)
                }
                fullWidth
                disabled={
                  editItem && loc.locale === "en" && localeType !== "en"
                }
                variant="standard"
              />
            </Grid>
          ))
        ) : (
          <Grid item xs={12} key={loc.locale}>
            <TextField
              id={loc.locale}
              label={loc.locale}
              value={translations[loc.locale]?.singular || ""}
              onChange={(e) =>
                handleTranslationChange(loc.locale, e.target.value, "singular")
              }
              fullWidth
              variant="standard"
              disabled={editItem && loc.locale === "en" && localeType !== "en"}
            />
          </Grid>
        )
      );
    }
  };

  const handleSubmit = () => {
    const selectedLocale = editItem == null ? "en" : localeType;

    let pluralizations = {};
    if (isPlural) {
      const localeTranslations = translations[selectedLocale] || {};
      pluralizations = Object.keys(localeTranslations).reduce((acc, key) => {
        if (localeTranslations[key]) {
          acc[key] = localeTranslations[key];
        }
        return acc;
      }, {});
      if (Object.keys(pluralizations).length === 0) {
        toast.dismiss();
        toast.error("Pluralizations are missing for the selected locale.", {
          position: toast.POSITION.TOP_RIGHT,
        });
        return;
      }
    } else {
      pluralizations = null;
      const universalValue = translations[selectedLocale]?.singular || null;
      if (universalValue === "" || universalValue === null) {
        toast.dismiss();
        toast.error("Universal value is missing or empty.", {
          position: toast.POSITION.TOP_RIGHT,
        });
        return;
      }
    }

    const postData = {
      key,
      locale: selectedLocale,
      universal: !isPlural
        ? translations[selectedLocale]?.singular || null
        : null,
      pluralizations: isPlural ? pluralizations : null,
    };

    setSubmiting(true);
    if (editItem != null) {
      postData.itemId = editItem.id;
      dispatch(postTranslationsEdit(postData, editItem.id));
    } else {
      dispatch(postTranslationsAdd(postData));
    }
  };

  const handleOnClose = (event, reason) => {
    if (reason && reason == "backdropClick" && "escapeKeyDown") {
      return;
    }
    if (editItem == null){
      clearData();
    }
    onClose();
  };

  const clearData = () => {
    setTranslations({});
    setIsPlural(false);
    setKey("");
    setLocaleType("");
    setIsEnEmpty(false);
  }

  return (
    <Dialog onKeyDown={event => {
      if (event.key === "Enter") {
        event.preventDefault();
        handleSubmit();
      }
    }} fullWidth open={open} onClose={handleOnClose}>
      <DialogTitle>
        <span>
          {editItem
            ? trans.edit_tranlsation_title
            : trans.add_tranlsation_title}
        </span>
        <IconButton
          onClick={handleOnClose}
          style={{ position: "absolute", right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              margin="dense"
              id="key"
              label={trans.translation_key}
              value={key}
              onChange={(e) => setKey(e.target.value)}
              fullWidth
              disabled={editItem != null}
              variant="standard"
            />
          </Grid>

          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="is_plural_label">{trans.is_plural}</InputLabel>
              <Select
                labelId="is_plural_label"
                id="is_plural_select"
                value={isPlural}
                onChange={(e) => setIsPlural(e.target.value)}
                label="Is Plural"
                disabled={localeType != "en" && editItem != null || !isEnEmpty && editItem != null}
              >
                <MenuItem value={false}>{trans.universal_only}</MenuItem>
                <MenuItem value={true}>{trans.with_pluralization}</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          {generateTextFields()}
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleOnClose} color="secondary">
          {trans.cancel_btn}
        </Button>
        <Button onClick={handleSubmit} color="primary" disabled={submiting}>
          {submiting ? <CircularProgress size={24} /> : trans.submit_btn}{" "}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddTranslationDialog;

import React, { useState, useEffect } from 'react';
import parse from 'html-react-parser';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { FormControl, InputLabel, MenuItem, Paper, Select, TableCell, TableRow, Button, TextField, CircularProgress } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { postDataContentAll, postDataContentDelete } from '../../redux/dataContent/action';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SweetAlert from 'sweetalert2';
import { DATA_CONTENT_BREEDS, DATA_CONTENT_VACCINES, PRIMARY_COLOR } from '../../constants';
import CustomTable from '../global/CustomTable';
import CustomTitle from '../global/CustomTitle';
import { toast } from 'react-toastify';
import { POST_DATA_CONTENT_DELETE, POST_VACCINES_DELETE } from '../../redux/actionTypes';
import AddDataContent from './AddDataContent';
import { getContentTypeItem, checkIsPublic } from '../../helpers/General';
import { postTranslationsAll } from '../../redux/translations/action';
import AddIcon from '@mui/icons-material/Add';
import UnpublishedOutlinedIcon from '@mui/icons-material/UnpublishedOutlined';
import DoneIcon from '@mui/icons-material/Done'
import VisibilityIcon from '@mui/icons-material/Visibility';
import AddTranslationDialog from '../translations/AddTranslation';
import { WatchLaterOutlined } from '@mui/icons-material';

const DataContent = (props) => {

  //Selectors
  const dispatch = useDispatch();
  const configurationData = useSelector(({ settings }) => settings.configurationData);
  const settingsData = useSelector(({ settings }) => settings.settingsData);
  const dataContentData = useSelector(({ dataContent }) => dataContent.dataContentData);
  const deleteDataContentData = useSelector(({ dataContent }) => dataContent.deleteDataContentData);
  const translationsData = useSelector(({ translations }) => translations.translationsData);

  //States
  const [dataContentArr, setDataContentArr] = useState([]);
  const [dataContentFiltered, setDataContentArrFiltered] = useState([]);
  const [addModal, setAddModal] = useState(false);  // Boolean to control dialog open state
  const [editItem, setEditItem] = useState(null);
  const [pageTitle, setPageTitle] = useState("");
  const [dialogTitle, setDialogTitle] = useState(settingsData.trans.add_item_title);
  const [speciesType, setSpeciesType] = useState(settingsData.species_types[0]);
  const [columnData, setColumnData] = useState([]);
  const [contentTypeItem, setContentTypeItem] = useState(null);
  const [dataLoading, setDataLoading] = useState(false);
  const [translationDialogOpen, setTranslationDialogOpen] = useState(false);
  const [translationEditItem, setTranslationEditItem] = useState(null);
  const [translationEditItemEn, setTranslationEditItemEn] = useState(null);
  const [searchKeyword, setSearchKeyword] = useState("");

  //Variables
  const trans = settingsData.trans;


  useEffect(() => {
    getTranslationsData();
  }, [speciesType]);


  useEffect(() => {
    setSpeciesType(settingsData.species_types[0]);
    setDataByContentType();
    getData();
  }, [props.contentType]);

  useEffect(() => {
    if (translationsData != null && contentTypeItem != null) {
      getData();
    }
  }, [translationsData, contentTypeItem]);

  useEffect(() => {
    if (dataContentArr) {
      if (searchKeyword != null && searchKeyword != ""){
        searchChanged(searchKeyword);
      }
    }
  }, [dataContentArr]);

  


  useEffect(() => {
    if (dataContentData) {
      if (dataContentData.contentType == props.contentType) {
        const sortedData = dataContentData.data.sort((a, b) => {
          if (a.order != null && a.order !== undefined && b.order != null &&  b.order !== undefined) {
            return a.order - b.order;
          }
          
          // If only one has 'order', prioritize it
          if (a.order !== undefined) return -1;
          if (b.order !== undefined) return 1;
        
          // If neither has 'order', sort by 'nameLocKey'
          return a.nameLocKey.localeCompare(b.nameLocKey);
        });
        setDataContentArr(sortedData);
        setDataContentArrFiltered(sortedData);
        setDataLoading(false);
      }
    }
  }, [dataContentData]);

  useEffect(() => { //DELETE DATA
    if (deleteDataContentData != null) {
      if (deleteDataContentData.contentType == props.contentType) {
        if (deleteDataContentData.data.status) {
          toast.dismiss();
          toast.success(deleteDataContentData.data.message, {
            position: toast.POSITION.TOP_RIGHT
          });

          getData();
        } else {
          toast.dismiss();
          toast.error(deleteDataContentData.data.message, {
            position: toast.POSITION.TOP_RIGHT
          });
        }
        dispatch({ type: POST_DATA_CONTENT_DELETE, payload: null });
      }
    }
  }, [deleteDataContentData]);

  const getTranslationsData = () => {
    dispatch(postTranslationsAll({}));
  }

  const setDataByContentType = () => {
    var contentItem = getContentTypeItem(settingsData, props.contentType);
    setContentTypeItem(contentItem);

    setPageTitle(trans[`${contentItem.name}`]);
    var tableColumns = [];

    tableColumns = [
      { id: 'name', align: true, disablePadding: false, label: trans.text_field_name + " " + "Key" },
    ];

    configurationData.supportedLocalizations.map((item, index) => {
      tableColumns.push({ id: "locale_type" + index, align: true, disablePadding: false, label: item.locale })
    });

    tableColumns.push({ id: 'isPublic', align: true, disablePadding: false, label: trans.generic_is_public_hint })

    if (contentItem.classification) {
      tableColumns.push({ id: 'classification', align: true, disablePadding: false, label: trans.table_vaccine_classification });
    }
    setColumnData(tableColumns);
  }

  const getData = () => {
    if (contentTypeItem != null) {
      setDataLoading(true);
      var selectedSpeciesUrl = contentTypeItem != null && contentTypeItem.hasSpecies ? speciesType.url : "";
      var endpoint = contentTypeItem != null && contentTypeItem.endpoint;
      dispatch(postDataContentAll(endpoint, props.contentType, selectedSpeciesUrl));
    }
  };

  const searchChanged = (value) => {
    setSearchKeyword(value);
    const normalizedSearchValue = value.toLowerCase();
    const filteredData = dataContentArr.filter(item => item.nameLocKey?.toLowerCase().includes(normalizedSearchValue));
    setDataContentArrFiltered(filteredData);
  };

  const onEditRow = (item) => {
    setDialogTitle(trans.edit_item_title);
    setEditItem(item);
    setAddModal(true);  // Open dialog
  };

  const onEditTranslationRow = (e, itemKey, localeItem) => {
    e.preventDefault();
    const foundEnItem = translationsData.find((element) => element.locale == "en" && element.key == itemKey);
    const foundLocaleItem = translationsData.find((element) => element.locale == localeItem.locale && element.key == itemKey);
    setTranslationEditItemEn(foundEnItem)
    setTranslationEditItem(foundLocaleItem);
    setTranslationDialogOpen(true);
  };

  const onDeleteRow = (item) => {
    SweetAlert.fire({
      theme: 'dark',
      title: trans.delete_dialog_title,
      text: trans.delete_dialog_desc,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: PRIMARY_COLOR,
      confirmButtonText: trans.btn_delete,
      cancelButtonText: trans.cancel_btn,
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        var selectedSpecies = contentTypeItem != null && contentTypeItem.hasSpecies ? speciesType.url : "";        
        dispatch(postDataContentDelete(
          contentTypeItem.endpoint,
          props.contentType, 
          selectedSpecies, 
          item.id,
          trans,
        ));
      }
    });
  };

  const onAddItem = () => {
    setDialogTitle(trans.add_item_title);
    setEditItem(null);
    setAddModal(true);  // Open dialog
  };

  const onDialogClose = () => {
    setAddModal(false);  // Close dialog
    setEditItem(null);
  };

  const onDialogTranslationClose = () => {
    setTranslationDialogOpen(false);
    setTranslationEditItem(false);
    setTranslationEditItemEn(false);
  }

  const isLocaleEnEmpty = (key) => {
    var isEmpty = false;

    translationsData.forEach(element => {
      if (element.key == key && element.locale == "en") {
        if (element.pluralizations == null && element.universal === null) {
          isEmpty = true;
        }
      }
    });
    return isEmpty;
  }

  const isLocaleEmpty = (key, localeType) => {
    var isEmpty = false;

    translationsData.forEach(element => {
      if (element.key == key && element.locale == localeType.locale) {
        if (element.pluralizations == null && element.universal === null) {
          isEmpty = true;
        }
      }
    });
    return isEmpty;
  }

  const onDialogTranslationSubmit = () => {
    getTranslationsData();
  }

  return (
    <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
            <Grid container spacing={2}>
              <Grid item md={10} sx={{ display: 'flex', alignItems: 'center' }}>
                <CustomTitle>{pageTitle}</CustomTitle>
              </Grid>
              <Grid item md={2}>
                <Button
                  startIcon={<EditIcon />}

                  color="secondary"
                  onClick={onAddItem}
                  variant="outlined"
                  fullWidth
                >
                  {trans.btn_add_new}
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>

      <br />

      <Grid item xs={12}>
        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
          <Grid container item md={12} spacing={2} alignItems="center">
            <Grid item xs={contentTypeItem != null && contentTypeItem.hasSpecies ? 10 : 12}>
              <TextField
                id="standard-search"
                label={trans.search_field}
                type="search"
                fullWidth
                variant="standard"
                onChange={(e) => searchChanged(e.target.value)}
              />
            </Grid>

            {contentTypeItem != null && contentTypeItem.hasSpecies ?
              <Grid item xs={2}>

                <FormControl fullWidth>
                  <InputLabel id="visible_state">{trans.select_species_type}</InputLabel>
                  <Select
                    labelId="visible_state"
                    id="visible"
                    value={speciesType.key}
                    label={trans.select_species_type}
                    onChange={(e) => setSpeciesType(settingsData.species_types.find(s => s.key == e.target.value))}>
                    {settingsData.species_types.map((item, index) => (
                      <MenuItem value={item.key} key={index}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid> : null}
          </Grid>

          <Grid item s={12} xs={12} md={10}>
            {dataLoading && dataContentFiltered.length == 0 ? <div style={{ minHeight: '150px', alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
              <CircularProgress />
            </div> :
              <CustomTable
                columnData={columnData}
                data={dataContentFiltered}
                totalData={dataContentFiltered.length}
                page={1}
                rowsPerPage={0}
                extraCellsNum={2}
                renderRow={(row) => (
                  <TableRow key={row.id}>
                    <TableCell>{<div>
                      <div style={{height:'100%',display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'start'}}>
                        {row.iconSVG ? <div style={{marginRight:'10px',marginTop:'5px'}}>
                        <img width={25} height={25} src={`data:image/svg+xml;base64,${window.btoa(row.iconSVG)}`} />
                      </div>: ""}
                        <div>{row.nameLocKey || ""}</div>
                      </div>
                     {row.detailsLocKey != null && row.detailsLocKey != "" ? <div style={{marginTop: '8px'}}>
                        {row.detailsLocKey || ""}
                      </div> : null}
                    </div>}</TableCell>
                    {configurationData.supportedLocalizations.map((localeItem, index) => {
                      return (<TableCell key={`translation_locale_${localeItem.locale}`}>{
                        isLocaleEnEmpty(row.nameLocKey) && localeItem.locale != "en" ? null :
                          <div>
                            {isLocaleEmpty(row.nameLocKey, localeItem) ?
                              <AddIcon style={{ cursor: 'pointer' }} onClick={(e) => onEditTranslationRow(e, row.nameLocKey, localeItem)} /> :
                              <EditIcon style={{ cursor: 'pointer' }} onClick={(e) => onEditTranslationRow(e, row.nameLocKey, localeItem)} />
                            }
                            {
                              contentTypeItem.details ? <div>
                                {isLocaleEmpty(row.detailsLocKey, localeItem) ?
                                  <AddIcon style={{ cursor: 'pointer' }} onClick={(e) => onEditTranslationRow(e, row.detailsLocKey, localeItem)} /> :
                                  <EditIcon style={{ cursor: 'pointer' }} onClick={(e) => onEditTranslationRow(e, row.detailsLocKey, localeItem)} />}
                              </div> : null
                            }
                          </div>


                      }
                      </TableCell>)
                    })}
                    {/* <TableCell>{getNameTranslation(row.nameLocKey || "")}</TableCell> */}
                    <TableCell style={{paddingLeft: "35px"}}>{checkIsPublic(row.publishedAt) == 2 ? <DoneIcon/> : checkIsPublic(row.publishedAt) == 1 ? <WatchLaterOutlined/> : <UnpublishedOutlinedIcon style={{color: "red"}}/>}</TableCell>
                    {contentTypeItem.classification == true ? <TableCell>{row.classification ? (row.classification === "core" ? trans.table_item_core : trans.table_item_non_core) : "N/A"}</TableCell> : null}
                    <TableCell><Button startIcon={<EditIcon />} size="small" color="secondary" onClick={() => onEditRow(row)} variant="outlined">{trans.btn_edit}</Button></TableCell>
                    {checkIsPublic(row.publishedAt) == 2 ? null : <TableCell><Button startIcon={<DeleteIcon />} size="small" color="error" onClick={() => onDeleteRow(row)} variant="outlined">{trans.btn_delete}</Button></TableCell>}
                  </TableRow>
                )}
              />}
          </Grid>
        </Paper>
      </Grid>

      {/* AddVaccine Dialog Component */}
      <AddDataContent
        open={addModal}  // Pass the open prop correctly
        onClose={onDialogClose}  // Correct function to close the dialog
        editItem={editItem}
        contentType={props.contentType}
        onSubmit={() => { getTranslationsData() }}
      />

      <AddTranslationDialog
        open={translationDialogOpen}
        onClose={onDialogTranslationClose}
        onSubmit={() => { onDialogTranslationSubmit() }}
        editItem={translationEditItem}  // Pass if you're editing an existing item
        editItemEn={translationEditItemEn}  // Pass if you're editing the English translation
      />

    </Container>
  );
};

export default DataContent;

import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from "@mui/material/Tooltip";
import TableSortLabel from "@mui/material/TableSortLabel";
import TablePagination from "@mui/material/TablePagination";
import Flag from 'react-world-flags';  // Import the flag component
import { localeToCountryCode } from '../../helpers/General';


const CustomTable = ({
  columnData,
  data,
  totalData,
  page = 0,
  rowsPerPage = 10,
  orderBy = '',
  order = 'asc',
  createSortHandler = () => {},
  onPageChange = () => {},
  onRowsPerPageChange = () => {},
  renderRow,
  hasPagination = false,
  extraCellsNum = 0
}) => {
  return (
    <div>
      <br />
      <Table size="small">
        <TableHead>
          <TableRow>
            {columnData.map((column) => (
              <TableCell
                key={column.id}
                align={column.numeric}
                padding={column.disablePadding ? "none" : "normal"}
              >
                <Tooltip
                  title="Sort"
                  placement={column.numeric ? "bottom-end" : "bottom-start"}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === column.id}
                    direction={order}
                    onClick={() => createSortHandler(column.id)}
                  >
                    {column.id.includes("locale_type") ?    
                     <Flag code={localeToCountryCode(column.label)} style={{ width: '24px', height: '16px' }} />
                      :column.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            ))}
            {
              Array.from({ length: extraCellsNum }, (_, index) => (
                <TableCell key={index} />
              ))
            }
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
              renderRow(row)
          ))}
        </TableBody>
      </Table>
   {hasPagination ?    <TablePagination
        component="div"
        count={totalData}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
      /> : null}
    </div>
  );
};

export default CustomTable;

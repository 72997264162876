import { getUserLocales } from 'get-user-locale';
import * as Const from '../constants';
import * as Store from '../helpers/Store';
import { USER_ID_SET, USER_TOKEN_SET } from '../redux/actionTypes';
import Moment from 'moment';

export const isEmptyOrSpaces = (str) => {
    return str === null || str.match(/^ *$/) !== null;
}

export const validURL =(str) => {
  var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
    '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
  return !!pattern.test(str);
}

export const getLocale = () => {
  var locale = getUserLocales()[0];
  if (locale.includes("-")){
    locale = locale.split('-')[0];
  }
  return locale;
}

export const isLoggedIn = () => {
  const token = Store.getData(USER_TOKEN_SET);
  let loggedIn =  token != null && token != "" && token != undefined;
  return loggedIn;
}

export const logout = () => {
  Store.removeData(USER_TOKEN_SET);
}


export const getLightsMode = () => {
   var mode = 'dark';
   if (Store.getData(Const.LIGHTS_MODE) != null && Store.getData(Const.LIGHTS_MODE) != ""){
    mode = Store.getData(Const.LIGHTS_MODE);
   }
   return mode;
}

export const storeLightsMode = (mode) => {
  Store.storeData(Const.LIGHTS_MODE,mode); 
}

export const getLocalDate = (date) => {
  var dateFormat = Const.DATE_FORMAT;
  var testDateUtc = Moment.utc(date);
  var localDate = testDateUtc.local();
 return localDate.format(dateFormat);
}


export const addZeroToNumbers = (num) => {
  var regExp = /^0[0-9].*$/;
   if (!regExp.test(num) && num <= 9){
      return `${0}${num}`;
   }
   return num;
}


export const convertBigNumber = (num) =>{
  if(num > 999 && num < 1000000){
      return (num/1000).toFixed(1) + 'K'; // convert to K for number from > 1000 < 1 million 
  }else if(num > 1000000){
      return (num/1000000).toFixed(1) + 'M'; // convert to M for number from > 1 million 
  }else if(num < 900){
      return num; // if value < 1000, nothing to do
  }
}

export const replaceAll = (str, find, replace) => {
  return str.replace(new RegExp(find, 'g'), replace);
}

export const localeToCountryCode = (locale) => {
  // Map locale to country code if necessary, or return the same if they match
  const mapping = {
    en: 'GB', // UK flag for English (you can also use 'US' if you prefer)
    fr: 'FR', // French
    de: 'DE', // German
    es: 'ES', // Spanish
    // Add other locale mappings as needed
  };
  return mapping[locale] || locale.toUpperCase(); // Fallback if no mapping found
};

export const getContentTypeItem = (settingsData, type) => {
  var contentitem = null;
  settingsData.data_contents_list.forEach(element => {
    if (type == element.type){
      contentitem = element;
    }
  });
  return contentitem;
}

export const checkIsPublic = (publishedAt) => {   
  if (publishedAt == null) {    
    return 0 //Unpublished
  } else if (Date.parse(publishedAt) > new Date()) {    
    return 1 //Future publish
  } else {    
    return 2 //Published
  }
}
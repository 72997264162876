import React from 'react';
import { useSelector } from "react-redux";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const SpeciesSelector = ({ 
    multiSelect = false, 
    selectedSpecies = [],  // Receive selected species from parent
    onChange 
}) => {

    const settingsData = useSelector(({ settings }) => settings.settingsData);
    const trans = settingsData.trans;

    // Get speciesOptions from settingsData
    const speciesOptions = settingsData.species_types.map(species => species.name);

    // Handler for selection change
    const handleSpeciesChange = (event, value) => {
        if (value) {
            var newValue = settingsData.species_types.filter(species => value.includes(species.name)).map(species => species.key)                
        } else {
            var newValue = []
        }
        if (onChange) {
            onChange(newValue);
        }
    };

    const populateSelectedSpecies = (species) => {
        return species.map(s => settingsData.species_types.find(e => e.key == s).name)        
    };

    const getOptionLabel = (option) => {
        return Array.isArray(option) ? option[0] || "" : option
    };

    const isOptionEqualToValue = (option, value) => {
        return Array.isArray(value) ? value.includes(option) : option === value
    };

    return (
        <div style={{marginTop:'5px'}}>
            <Autocomplete
                multiple={multiSelect}
                fullWidth
                id="species-selector"
                options={speciesOptions}
                disableCloseOnSelect={multiSelect}
                value={populateSelectedSpecies(selectedSpecies)}
                onChange={handleSpeciesChange}
                getOptionLabel={getOptionLabel}
                isOptionEqualToValue={isOptionEqualToValue}
                renderOption={(props, option, { selected }) => (
                    <li {...props} key={option}>
                        {multiSelect && (
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                            />
                        )}
                        {option}
                    </li>
                )}
                renderInput={(params) => (
                    <TextField {...params} label={trans.select_species_picker} placeholder={trans.select_species_picker} />
                )}
            />
        </div>
    );
};

export default SpeciesSelector;

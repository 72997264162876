import {
  POST_SIGN_IN,
  POST_SIGN_OUT,
  POST_SIGN_UP,
  POST_USER_INFO,
  USER_TOKEN_SET,
  USER_ID_SET,
  POST_USERS_ALL
  } from '../actionTypes';
  import axios from '../../helpers/Api'
  import * as General from '../../helpers/General'
  import * as Store from '../../helpers/Store';
  import * as Const from '../../constants';
import { DEBUG_MODE } from '../../constants';
import { decryptToken, encryptToken } from '../../helpers/TokenEncryptor';
import { googleLogout } from '@react-oauth/google';

export const postSignIn = (token,givenName,familyName) => async dispatch => {
  try {

    var timeZoneId = Intl.DateTimeFormat().resolvedOptions().timeZone;

    //Get user locale of the machine he is on
    const response = await axios.post(`/v1/sessions/oauth`,{data: {
      givenName,
      familyName,
      timeZoneId
    }}, {
      headers: {
        'Authorization': `Bearer ${token}`
      },
    });

    if (DEBUG_MODE){
      console.log(response.data);
    }

    if (response.data.data.token != null && response.data.data.token != ""){
      Store.storeData(USER_TOKEN_SET,encryptToken(response.data.data.token));
    } else {
      Store.storeData(USER_TOKEN_SET,"");
    }

    dispatch({type: POST_SIGN_IN, payload: response.data.data});
  }catch(e) {
    if (DEBUG_MODE){
      console.log(e);
    }
    dispatch({type: POST_SIGN_IN, payload: null});
  }
};

    
export const postUserInfo = () => async dispatch => {
  try {
    const token = decryptToken(Store.getData(USER_TOKEN_SET));

    //USER IS LOGGED IN. SO WE STORE THE TOKEN HEADR TO AXIOS FOR LATER USE INTO OUR API
    axios.defaults.headers.common['Authorization'] = "Bearer " + token;
  
    const response = await axios.get(`/krypton/v1/users/me`);
  
    if (DEBUG_MODE){
      console.log(response.data);
    }
  
    dispatch({type: POST_USER_INFO, payload: response.data.data});
  }catch(e) {
    if (DEBUG_MODE){
      console.log(e);
    }
    General.logout();
    dispatch({type: POST_USER_INFO, payload: null});
  }

};


export const postLogout = () => async dispatch => {
  //First we logout from google
  googleLogout();

  //Then we remove the token and user data
  Store.removeData(USER_TOKEN_SET);
  
  //Clear the header
  axios.defaults.headers.common['Authorization'] = ""

  dispatch({type: POST_SIGN_OUT, payload: {status: true}});
};


export const postUsersAll = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;

  const response = await axios.post(`/krypton/v1/users`,{data: postData});

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_USERS_ALL, payload: response.data.data});
};

import {
  POST_TRANSLATIONS_ALL,
  POST_TRANSLATIONS_ADD,
  POST_TRANSLATIONS_EDIT,
  POST_TRANSLATIONS_DELETE,
  USER_TOKEN_SET
  } from '../actionTypes';
  import axios, { handleApiResponse } from '../../helpers/Api'
  import * as General from '../../helpers/General'
  import * as Store from '../../helpers/Store';
  import * as Const from '../../constants';
import { DEBUG_MODE } from '../../constants';
import { decryptToken } from '../../helpers/TokenEncryptor';
  
 
  
    
export const postTranslationsAll = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;

  const token = decryptToken(Store.getData(USER_TOKEN_SET));

  const response = await axios.get(`/krypton/v1/localizations`,{
    headers: {
      'Authorization': `Bearer ${token}`
    },
  });

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_TRANSLATIONS_ALL, payload: response.data.data});
};

    
export const postTranslationsAdd = (postData) => async dispatch => {
  try {
    if (DEBUG_MODE){
      console.log(postData);
    }

    const response = await axios.post(`/krypton/v1/localizations`,{data: postData});

    if (DEBUG_MODE){
      console.log(response.data);
    }

    dispatch({type: POST_TRANSLATIONS_ADD, payload: handleApiResponse(response)});
  } catch (error) {
    if (DEBUG_MODE){
      console.log(error);
    }
    dispatch({type: POST_TRANSLATIONS_ADD, payload: handleApiResponse(error.response)});
  }
};

export const postTranslationsAddAsync = async (postData) => {
  try {
    if (DEBUG_MODE) {
      console.log(postData);
    }

    const response = await axios.post(`/krypton/v1/localizations`, { data: postData });

    if (DEBUG_MODE) {
      console.log(response.data);
    }
    
    if (response.status == 409){
      return { status: true, message: 'Success', status_code: response.status };
    } else {
      return handleApiResponse(response);
    }

  } catch (error) {
    if (DEBUG_MODE) {
      console.log(error);
    }
    
    if (error.response.status == 409){
      return { status: true, message: 'Success', status_code: error.response.status };
    } else {
      return handleApiResponse(error.response);
    }
  }
};

export const postTranslationsEdit = (postData, translationKey) => async dispatch => {
  try {
    const response = await axios.put(`/krypton/v1/localizations/${translationKey}`,{data: postData});

    if (DEBUG_MODE){
      console.log(response.data);
    }

    dispatch({type: POST_TRANSLATIONS_EDIT, payload: handleApiResponse(response)});
  } catch (error) {
    if (DEBUG_MODE){
      console.log(error);
    }
    dispatch({type: POST_TRANSLATIONS_EDIT, payload: handleApiResponse(error.response)});
  }
};

export const postTranslationsDelete = (translationKey,trans) => async dispatch => {
  try {
    const response = await axios.delete(`/krypton/v1/localizations/${translationKey}`);

    if (DEBUG_MODE){
      console.log(response.data);
    }

    var responseData = handleApiResponse(response);
    responseData.message = trans.delete_success;

    dispatch({type: POST_TRANSLATIONS_DELETE, payload: responseData});
  } catch (error) {
    if (DEBUG_MODE){
      console.log(error);
    }
    dispatch({type: POST_TRANSLATIONS_DELETE, payload: handleApiResponse(error.response)});
  }
};

export const postTranslationsDeleteAsync = async (translationKey, trans) => {
  try {
    const response = await axios.delete(`/krypton/v1/localizations/${translationKey}`);

    if (DEBUG_MODE) {
      console.log(response.data);
    }

    const responseData = handleApiResponse(response);
    responseData.message = trans.delete_success;

    // Return the handled response instead of dispatching
    return responseData;

  } catch (error) {
    if (DEBUG_MODE) {
      console.log(error);
    }

    // Return the error response after handling it
    return handleApiResponse(error.response);
  }
};

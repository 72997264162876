import React, {Fragment,useEffect,useState} from 'react';
import { BrowserRouter as Router, Route, Routes,Navigate} from 'react-router-dom';
import Home from './home';
import Login from './login';
import PrivateRoute from './privateRoute';
import {Provider} from 'react-redux';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useParams } from 'react-router';
import history from '../history';

import * as General from '../helpers/General'
import * as Store from '../helpers/Store';
import {  LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { DEBUG_MODE } from '../constants';
import { getGoogleClientId } from '../helpers/getEnvValues';


const MainApp = () => {

  const [darkModeChecked, setDarkModeChecked] = useState(true);

  const theme = createTheme({
    components: {
      MuiTypography: {
        defaultProps: {
          variantMapping: {
            h1: 'h2',
            h2: 'h2',
            h3: 'h2',
            h4: 'h2',
            h5: 'h2',
            h6: 'h2',
            subtitle1: 'h2',
            subtitle2: 'h2',
            body1: 'span',
            body2: 'span',
          },
        },
      },
    },
    palette: {
      primary: {
        main: "#f05138",
      },
      secondary: {
        main: "#133c6c",
      },
      mode: darkModeChecked ? 'dark' : 'light',
      ...(!darkModeChecked
      ? {
          primary: {
            main: "#000",
          },
          background: {
            default: "#f5f7fa",
            paper: "#ffffff",
          },
        }
      : {
          primary: {
            main: "#fff",
          },
          secondary: {
            main: "#f5f7fa",
          },
          background: {
            default: "#123b6b",
            paper: "#001741",
          },
        }),
    },
  });


  const onModeChange = (isDarkChecked) => {
    setDarkModeChecked(isDarkChecked);
  }


  useEffect(()=>{
    setDarkModeChecked(General.getLightsMode() == "dark")
    },[]);


  
  return (
    <div> 
      <GoogleOAuthProvider clientId={getGoogleClientId()}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
      <ThemeProvider theme={theme}>
      <Fragment>
      <Router location={history.location} navigator={history}>
    
          <Routes>
            {/* <Route exact path='/' element={<PrivateRoute exact path='/'/>}>
              <Route exact path='/' element={<Home/>}/>
              <Route exact path='/dashboard' element={<Home/>}/>
              <Route exact path='/categories' element={<Home/>}/>
              <Route exact path='/sources' element={<Home/>}/>
              <Route exact path='/tags' 
              element={<Home/>}/>
            </Route> */}
            <Route  path='/' element={<PrivateRoute path={'/'}><Navigate to="/admin"></Navigate></PrivateRoute>}/> 
            <Route  path='admin/*' element={<PrivateRoute path={'admin/*'}><Home onModeChange={onModeChange}/></PrivateRoute>}/> 
            {/* <Route  path='admin/*' element={<PrivateRoute  path='admin/*'/>}>
              <Route  path='admin/*' element={<Home/>}/>
            </Route> */}
            {/* <Route exact path='/dashboard' element={<PrivateRoute exact path='/dashboard'/>}>
              <Route exact path='/dashboard' element={<Home/>}/>
            </Route>
            */}
            <Route  path='/login' element={<PrivateRoute path={'/login'}><Login /></PrivateRoute>}/> 
            {/* <Route exact element={<PrivateRoute exact path='/login'/>}>
              <Route exact path='/login' element={<Login/>}/>
            </Route> */}
            {/* <Route exact path='/register' element={<Register/>}/> */}
            {/* <Route exact path='/login' element={<Login/>}/> */}
          </Routes>
        
        
      </Router>
      </Fragment>
      </ThemeProvider>
      <ToastContainer/>
      </LocalizationProvider>
      </GoogleOAuthProvider>
    </div>
  );
};

export default MainApp;

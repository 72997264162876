import CryptoJS from 'crypto-js';
import { DEBUG_MODE } from '../constants';
import { getAppTokenSecretKey } from './getEnvValues';

export const encryptToken = (token) => {
    const ciphertext = CryptoJS.AES.encrypt(token, getAppTokenSecretKey()).toString();
    return ciphertext;
}
 
export const decryptToken = (encryptedToken) => {
    const bytes = CryptoJS.AES.decrypt(encryptedToken, getAppTokenSecretKey());
    const originalText = bytes.toString(CryptoJS.enc.Utf8);
    return originalText;
}

import {
  POST_DATA_CONTENT_ALL,
  POST_DATA_CONTENT_ADD,
  POST_DATA_CONTENT_EDIT,
  POST_DATA_CONTENT_DELETE
} from '../actionTypes';

const INIT_STATE = {
  dataContentData: null,
  addDataContentData: null,
  editDataContentData: null,
  deleteDataContentData: null,
};

const reducer = (state = INIT_STATE, action) => {
  const { type, payload, contentType } = action;

  switch (type) {
    case POST_DATA_CONTENT_ALL:
      return {
        ...state,
        dataContentData: { data: payload, contentType },
      };

    case POST_DATA_CONTENT_ADD:
      return {
        ...state,
        addDataContentData: { data: payload, contentType },
      };

    case POST_DATA_CONTENT_EDIT:
      return {
        ...state,
        editDataContentData: { data: payload, contentType },
      };

    case POST_DATA_CONTENT_DELETE:
      return {
        ...state,
        deleteDataContentData: { data: payload, contentType },
      };

    default:
      return state;
  }
};

export default reducer;

import {
  POST_SIGN_IN,
  POST_SIGN_OUT,
  POST_SIGN_UP,
  POST_USER_INFO,
  USER_TOKEN_SET,
  POST_USERS_ALL
} from '../actionTypes';

import * as Store from '../../helpers/Store';
  
const INIT_STATE = {
  signInData: null,
  signUpData: null,
  logoutData: null,
  userToken: null,
  userInfoData: null,
  userAllData: null
};

const reducer = (state = INIT_STATE, action) => {
        switch(action.type) {
          case POST_SIGN_IN:
                   return {
                     ...state,
                     signInData: action.payload
          }
          case POST_SIGN_UP:
            return {
              ...state,
              signUpData: action.payload
          }
          case POST_SIGN_OUT:
            return {
              ...state,
              logoutData: action.payload
          }
          case POST_USER_INFO:
            return {
              ...state,
              userInfoData: action.payload
          }
          case USER_TOKEN_SET:
            return {
            ...state,
            userToken: action.payload
          }
          case POST_USERS_ALL:
            return {
            ...state,
            userAllData: action.payload
          }
        default:
              return state;
        }
};
      
export default reducer;
import React, { useState,useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Link } from "react-router-dom";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { WEB_SITE_NAME, WEB_SITE_URL } from '../../constants';
import {useDispatch, useSelector} from "react-redux";
import { postSignIn, postUserInfo, postUsersAll } from '../../redux/auth/action';
import { toast } from 'react-toastify';
import { POST_SIGN_IN } from '../../redux/actionTypes';

const Users = () => {

const dispatch = useDispatch();
const settingsData = useSelector(({settings}) => settings.settingsData);
const userInfoData = useSelector(({auth}) => auth.userInfoData);
const [loading,setLoading] = useState(false);

// const trans = settingsData.trans;

  //HOOKS
  useEffect(() => { //ONLOAD
    //getData();
   }, []);
  
  const getData = () => {

    dispatch(postUsersAll({})); 
  }

  return (
      <Container component="main" maxWidth="xs">
        

      </Container>
  );
}

export default Users;
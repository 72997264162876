import {
  GET_SETTINGS,
  GET_CONFIGURATION,
  USER_TOKEN_SET
  } from '../actionTypes';
  import axios from '../../helpers/Api'
  import * as General from '../../helpers/General'
import { DEBUG_MODE } from '../../constants';
import settingsJsonData from '../../data/settings.json';
import enData from '../../data/lang/en.json';
import { decryptToken } from '../../helpers/TokenEncryptor';
import * as Store from '../../helpers/Store';
  
export const getSettings = () => async dispatch => {
      //Get user locale of the machine he is on
    const userLocale = General.getLocale();

    // const response = await axios.get(`/krypton/v1/configuration`);
    // if (DEBUG_MODE){
    //   console.log(response.data);
    // }
    
    const settingsData = settingsJsonData;
    settingsData.trans = enData;

    // console.log("settingsData",settingsData);
    dispatch({type: GET_SETTINGS, payload: settingsData});
};

    
export const getConfigurations = () => async dispatch => {
  try {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();

  const token = decryptToken(Store.getData(USER_TOKEN_SET));

  const response = await axios.get(`/krypton/v1/configuration`,{
    headers: {
      'Authorization': `Bearer ${token}`
    },
  });
  if (DEBUG_MODE){
    console.log(JSON.stringify(response.data.data));
  }

  dispatch({type: GET_CONFIGURATION, payload: response.data.data});
  } catch (ex) {

  }

};
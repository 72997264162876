import {
  POST_DATA_CONTENT_ALL,
  POST_DATA_CONTENT_ADD,
  POST_DATA_CONTENT_EDIT,
  POST_DATA_CONTENT_DELETE
  } from '../actionTypes';
  import axios, { handleApiResponse } from '../../helpers/Api'
  import * as General from '../../helpers/General'
  import * as Store from '../../helpers/Store';
  import * as Const from '../../constants';
import { DEBUG_MODE } from '../../constants';
  
   
export const postDataContentAll = (endpoint,contentType,species) => async dispatch => {
  try {

    var fixedEndpoint = endpoint;
    if (endpoint.includes("species")){
      fixedEndpoint = endpoint.replace("species", species);
    } 
  
    var url = `/krypton/v1/${fixedEndpoint}`;

    const response = await axios.get(url);

    if (DEBUG_MODE){
      console.log(response.data);
    }

    dispatch({type: POST_DATA_CONTENT_ALL, payload: response.data.data,contentType});
 
  } catch (e){
    if (DEBUG_MODE){
      console.log(e);
    }

    dispatch({type: POST_DATA_CONTENT_ALL, payload: [], contentType});
  } 
};

    
export const postDataContentAdd = (endpoint,contentType,species,postData) => async dispatch => {
  try {
    var fixedEndpoint = endpoint;
    if (endpoint.includes("species")){
      fixedEndpoint = endpoint.replace("species", species);
    }
  
    var url = `/krypton/v1/${fixedEndpoint}`;
    
    const response = await axios.post(url,{data: postData});

    if (DEBUG_MODE){
      console.log(response);
    }

    dispatch({type: POST_DATA_CONTENT_ADD, payload: handleApiResponse(response),contentType});
  } catch (error) {
    if (DEBUG_MODE){
      console.log(error);
    }
    dispatch({type: POST_DATA_CONTENT_ADD, payload: handleApiResponse(error.response),contentType});
  }
};

export const postDataContentEdit = (endpoint,contentType,species,postData,itemID) => async dispatch => {
  try {

    var fixedEndpoint = endpoint;
    if (endpoint.includes("species")){
      fixedEndpoint = endpoint.replace("species", species);
    }
  
    var url = `/krypton/v1/${fixedEndpoint}/${itemID}`;

    const response = await axios.put(url,{data: postData});

    if (DEBUG_MODE){
      console.log(response.data);
    }

    dispatch({type: POST_DATA_CONTENT_EDIT, payload: handleApiResponse(response),contentType});
  } catch (error) {
    if (DEBUG_MODE){
      console.log(error);
    }
    dispatch({type: POST_DATA_CONTENT_EDIT, payload: handleApiResponse(error.response),contentType});
  }
};

export const postDataContentDelete = (endpoint,contentType,species,itemID,trans) => async dispatch => {
  try {

    var fixedEndpoint = endpoint;
    if (endpoint.includes("species")){
      fixedEndpoint = endpoint.replace("species", species);
    }
  

    var url = `/krypton/v1/${fixedEndpoint}/${itemID}`;


    const response = await axios.delete(url);

    if (DEBUG_MODE){
      console.log(response.data);
    }

    var responseData = handleApiResponse(response);
    responseData.message = trans.delete_success;

    dispatch({type: POST_DATA_CONTENT_DELETE, payload: responseData,contentType});
  } catch (error) {
    if (DEBUG_MODE){
      console.log(error);
    }
    dispatch({type: POST_DATA_CONTENT_DELETE, payload: handleApiResponse(error.response),contentType});
  }
};

import {combineReducers} from 'redux';
import settings from './settings/reducer';
import auth from './auth/reducer';
import upload from './upload/reducer';
import translations from './translations/reducer';
import dataContent from './dataContent/reducer';

export default combineReducers({
    settings: settings,
    auth: auth,
    upload: upload,
    translations:translations,
    dataContent: dataContent
  });

import React, { useState } from 'react';
import {  TextField } from '@mui/material';

const MyColorPicker = (props) => {


  return(<div className='_flex_row'>
     <TextField

      label={" "}
      style={{width:"30px",marginRight:'5px'}}
      type="color"
      value={props.color}
      onChange={(event) => {props.onChangeColor(event.target.value)}}
      variant="standard"
      disabled={props.disabled}
      // You can add additional TextField props as needed
    />

      <TextField
              label={`${props.labelText}`}
              id="color_picker_text"
              value={props.color}
              onChange={(event) => {props.onChangeColor(event.target.value)}}
              type="text"
              fullWidth
              variant="standard" 
              inputProps={{maxLength:7}}
              disabled={props.disabled}
              />
  </div>);
};

export default MyColorPicker;

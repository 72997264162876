import * as React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';

function CustomTitle(props) {
  return (
    <Typography
      component="h2"
      variant="h6"
      color="primary"
      gutterBottom
      style={{ display: 'flex', alignItems: 'center' }}
    >
      {props.children}
    </Typography>
  );
}

CustomTitle.propTypes = {
  children: PropTypes.node,
};

export default CustomTitle;

import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { FormControl, InputLabel, MenuItem, Paper, Select, TableCell, TableRow } from '@mui/material';
import CustomTitle from '../global/CustomTitle';
import CustomTable from '../global/CustomTable';
import { useDispatch, useSelector } from "react-redux";
import { postTranslationsAdd, postTranslationsAll, postTranslationsDelete, postTranslationsDeleteAsync, postTranslationsEdit } from '../../redux/translations/action';
import TextField from '@mui/material/TextField';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import SweetAlert from 'sweetalert2';
import { PRIMARY_COLOR } from '../../constants';
import FormDialog from '../global/formDialog';
import AddTranslation from './AddTranslation';
import { toast } from 'react-toastify';
import { POST_TRANSLATIONS_ADD, POST_TRANSLATIONS_DELETE, POST_TRANSLATIONS_EDIT } from '../../redux/actionTypes';
import EditTranslationKey from './EditTranslationKey';

const Translations = () => {

  const dispatch = useDispatch();
  const settingsData = useSelector(({ settings }) => settings.settingsData);
  const configurationData = useSelector(({ settings }) => settings.configurationData);
  const translationsData = useSelector(({ translations }) => translations.translationsData);
  const deleteTranslationData = useSelector(({ translations }) => translations.deleteTranslationData);

  const [loadedFirstTime, setLoadedFirstTime] = useState(false);
  const trans = settingsData.trans;
  const [tranlsationsArr, setTranslationsArr] = useState([]);
  const [tranlsationsFilteredHolder, setTranslationsArrFilteredHolder] = useState([]);
  const [tranlsationsFiltered, setTranslationsArrFiltered] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [addModal, setAddModal] = useState(false);
  const [editItem, setEditItem] = useState(null);
  const [editItemEn, setEditItemEn] = useState(null);
  const [columnData, setColumnData] = useState([]);


  const [editItemKey, setEditItemKey] = useState(null);
  const [editTranslationKeyModal, setEditTranslationKeyModal] = useState(false);
  const [emptyFiltering, setEmptyFiltering] = useState('none');



  //HOOKS
  useEffect(() => { //ONLOAD

    var tableHeaders = [];
    tableHeaders.push({ id: "key", align: true, disablePadding: false, label: trans.table_translation_id });
    tableHeaders.push({ id: "universal", align: true, disablePadding: false, label: trans.table_translation_value_text });

    configurationData.supportedLocalizations.map((item, index) => {
      tableHeaders.push({ id: "locale_type_translation" + index, align: true, disablePadding: false, label: item.locale })
    });



    setColumnData(tableHeaders);

    getData();
  }, []);

  useEffect(() => {
    if (translationsData != null) {
      var filteredTranslations = [];
      let translationMap = {};


      // Step 1: Loop through translationsData and group by "key"
      translationsData.forEach(element => {
        const { id, key, locale, universal, pluralizations } = element;

        // Step 2: Check if the key already exists in the map
        if (!translationMap[key]) {
          // Step 3: Initialize the key with an array of objects for each locale
          translationMap[key] = {
            key: key,
            translations: []
          };
        }

        // Step 4: Add the locale, universal, and pluralizations to the respective key
        translationMap[key].translations.push({
          id: id,
          locale: locale,
          universal: universal,
          pluralizations: pluralizations
        });
      });

      // Step 5: Convert the translationMap into an array and push it to filteredTranslations
      Object.values(translationMap).forEach(groupedData => {
        filteredTranslations.push(groupedData);
      });


      filteredTranslations = filteredTranslations.sort((a, b) => {
        if (a.key < b.key) return -1; // a comes before b
        if (a.key > b.key) return 1;  // a comes after b
        return 0; // a and b are equal
      });

      setTranslationsArr(filteredTranslations);
      setTranslationsArrFilteredHolder(filteredTranslations);
      setTranslationsArrFiltered(filteredTranslations);
      setLoadedFirstTime(true);
    }
  }, [translationsData]);


  useEffect(() => { //SEARCH WORD CHAGNED
    if (searchKeyword != null && loadedFirstTime) {
      filterDataCombined();
    }
  }, [searchKeyword]);


  useEffect(() => { //SEARCH WORD CHAGNED
    if (emptyFiltering != null && loadedFirstTime) {
      filterDataCombined();
    }
  }, [emptyFiltering]);

  


  useEffect(() => { //DELETE DATA
    if (deleteTranslationData != null) {
      if (deleteTranslationData.status) {
        toast.dismiss();
        toast.success(deleteTranslationData.message, {
          position: toast.POSITION.TOP_RIGHT
        });
        getData();
      } else {
        toast.dismiss();
        toast.error(deleteTranslationData.message, {
          position: toast.POSITION.TOP_RIGHT
        });
      }
      dispatch({ type: POST_TRANSLATIONS_DELETE, payload: null });
    }
  }, [deleteTranslationData]);


  useEffect(() => { //DELETE DATA
    if (tranlsationsFilteredHolder) {
      if (searchKeyword != null && searchKeyword != "") {
        filterDataCombined();
      }
    }
  }, [tranlsationsFilteredHolder]);




  //FUNCTIONS
  const getData = () => {
    dispatch(postTranslationsAll({}));
  }

  const searchChanged = (value) => {
    setSearchKeyword(value);
  }

  const filterDataCombined = () => {
    let intermediateFilteredData = [];

    // Check if search keyword is empty
    if (searchKeyword === "") {
      intermediateFilteredData = tranlsationsFilteredHolder;
    } else {
      const normalizedSearchValue = searchKeyword.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");

      // Filter based on normalized values of key, universal, and pluralizations in translations
      intermediateFilteredData = tranlsationsFilteredHolder.filter(item => {
        const normalizedKey = item.key.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");

        // Check if key contains the search value
        const isKeyMatch = normalizedKey.includes(normalizedSearchValue);

        // Check if any translation's universal or pluralizations match the search value
        const isTranslationMatch = item.translations.some(translation => {
          // Normalize universal
          const normalizedUniversal = translation.universal ? translation.universal.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "") : "";
          // Check pluralizations (if any)
          const normalizedPluralizations = translation.pluralizations
            ? Object.values(translation.pluralizations).filter(Boolean).map(pluralization =>
              pluralization.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
            )
            : [];

          // Check if universal or any pluralization contains the search value
          return (
            normalizedUniversal.includes(normalizedSearchValue) ||
            normalizedPluralizations.some(pluralization => pluralization.includes(normalizedSearchValue))
          );
        });

        // Return true if either key or translation match
        return isKeyMatch || isTranslationMatch;
      });
    }

    if (emptyFiltering != "none"){
      var langFilteredTranslations = [];
  
      intermediateFilteredData.forEach(intermediateFilteredDataItem => {
        intermediateFilteredDataItem.translations.forEach(translation => {
          if (translation.locale == emptyFiltering){

            if (translation.pluralizations == null && translation.universal === null) {
              langFilteredTranslations.push(intermediateFilteredDataItem);
            }
          }
        });
      });
      intermediateFilteredData = langFilteredTranslations;
    }

    setTranslationsArrFiltered(intermediateFilteredData);
  };


  const onEditRow = (e, item, localeItem) => {
    e.preventDefault();
    const foundEnItem = translationsData.find((element) => element.locale == "en" && element.key == item.key);
    const foundLocaleItem = translationsData.find((element) => element.locale == localeItem.locale && element.key == item.key);
    setEditItemEn(foundEnItem)
    setEditItem(foundLocaleItem);
    setAddModal(true);
  }

  const onEditRowKey = (e, item) => {
    e.preventDefault();
    const foundEnItem = translationsData.find((element) => element.locale == "en" && element.key == item.key);
    console.log(foundEnItem);

    setEditItemKey(foundEnItem);
    setEditTranslationKeyModal(true);
  }

  const onDeleteRow = (e, item) => {
    e.preventDefault();
    SweetAlert.fire({
      theme: "dark",
      title: trans.delete_dialog_title,
      text: trans.delete_dialog_desc,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: PRIMARY_COLOR,
      confirmButtonText: trans.btn_delete,
      cancelButtonText: trans.cancel_btn,
      reverseButtons: true
    }).then(async (result) => {
      if (result.value) {
        try {

          // Use Promise.all to wait for all delete requests          
          // Delete only "en" the backend will delete all other languages
          const deleteResponses = await Promise.all(
            item.translations.filter(element => element.locale == "en").map(element => postTranslationsDeleteAsync(element.id, trans))
          );

          // Check the last response
          const lastResponse = deleteResponses[deleteResponses.length - 1];

          if (lastResponse.status) {
            toast.dismiss();
            toast.success(lastResponse.message, {
              position: toast.POSITION.TOP_RIGHT
            });
            getData(); // Call to refresh or load data after successful deletion
          } else {
            toast.dismiss();
            toast.error(lastResponse.message, {
              position: toast.POSITION.TOP_RIGHT
            });
          }

          // Optionally dispatch null payload after handling the deletion
          dispatch({ type: POST_TRANSLATIONS_DELETE, payload: null });

        } catch (error) {
          // Handle any errors that occur during deletion
          toast.dismiss();
          toast.error("An error occurred while deleting translations.", {
            position: toast.POSITION.TOP_RIGHT
          });
          console.error(error);
        }
      }
    });
  };


  const onAddItem = (e) => {
    e.preventDefault();
    //OPEN THE ADD NEW MODAL
    setEditItem(null);
    setEditItemEn(null);
    setAddModal(true);
  }

  const onDialogClose = () => {
    setAddModal(false);
  }

  const onDialogSubmit = () => {
    getData();
  }

  const onDialogTranslationKeyClose = () => {
    setEditTranslationKeyModal(false);
  }

  const onDialogSubmitTranslationKey = () => {
    getData();
  }

  const isLocaleEmpty = (translationItem, localeType) => {
    var isEmpty = false;

    translationItem.translations.forEach(element => {
      if (element.locale == localeType.locale) {
        if (element.pluralizations == null && element.universal === null) {
          isEmpty = true;
        }
      }
    });
    return isEmpty;
  }

  const isLocaleEnEmpty = (translationItem) => {
    var isEmpty = false;

    translationItem.translations.forEach(element => {
      if (element.locale == "en") {
        if (element.pluralizations == null && element.universal === null) {
          isEmpty = true;
        }
      }
    });
    return isEmpty;
  }


  const getValueText = (item) => {
    var text = "N/A";
    item.translations.forEach(element => {
      if (element.locale == "en") {
        if (element.universal != null && element.universal != "") {
          text = element.universal;
        } else {
          if (element.pluralizations != null) {
            if (element.pluralizations.one != null) {
              text = element.pluralizations.one;
            }
          }
        }
      }
    });

    return text;
  }

  const handleEmptyFiltering = (value) => {
    setEmptyFiltering(value);
  }

  return (<Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
    <Grid item xs={12}>
      <Paper sx={{ p: 2, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <CustomTitle>{trans.menu_translations}</CustomTitle>


        <Button
          style={{ width: '15%' }}
          startIcon={<EditIcon />}
          size="small"
          color="secondary"
          onClick={(e) => onAddItem(e)}
          variant="outlined"
          fullWidth
        >
          {trans.btn_add_new}
        </Button>

      </Paper>


    </Grid>

    <br />

    <Grid item xs={12}>
      <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>


        <Grid container item md={12} spacing={2} alignItems="center">
          <Grid item xs={12} md={10}>
            <TextField
              id="standard-search"
              label={trans.search_field}
              type="search"
              fullWidth
              variant="standard"
              onChange={(e) => searchChanged(e.target.value)}
            />
          </Grid>

          <Grid item xs={2}>

            <FormControl fullWidth>
              <InputLabel id="empty_filtering">{trans.select_transltion_empty_filters}</InputLabel>
              <Select
                labelId="empty_filtering"
                id="empty_filtering"
                value={emptyFiltering} // Bind this to the state variable
                label={trans.select_transltion_empty_filters}
                onChange={(e) => handleEmptyFiltering(e.target.value)} // Update the state when selection changes
              >
                <MenuItem value={"none"} key={"none_filtering"}>
                  No Filter
                </MenuItem>
                {configurationData.supportedLocalizations.map((localeItem, index) => (
                  <MenuItem value={localeItem.locale} key={index}>
                    {localeItem.locale}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

          </Grid>


        </Grid>

        <Grid item s={12} xs={12} md={10}>
          <CustomTable
            columnData={columnData}
            data={tranlsationsFiltered}
            totalData={tranlsationsFiltered.length}
            page={1}
            rowsPerPage={0}
            extraCellsNum={2}
            renderRow={(row) => (
              <TableRow key={row.key}>
                <TableCell>{row.key != null && row.key !== "" ? row.key : "N/A"}</TableCell>
                <TableCell style={{
                  maxWidth: '200px', /* Adjust width as needed */
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}>
                  {getValueText(row)}
                </TableCell>
                {configurationData.supportedLocalizations.map((localeItem, index) => {
                  return (<TableCell key={`translation_locale_${localeItem.locale}`}>{
                    isLocaleEnEmpty(row) && localeItem.locale != "en" ? null : isLocaleEmpty(row, localeItem) ?
                      <AddIcon style={{ cursor: 'pointer' }} onClick={(e) => onEditRow(e, row, localeItem)} /> :
                      <EditIcon style={{ cursor: 'pointer' }} onClick={(e) => onEditRow(e, row, localeItem)} />}
                  </TableCell>)
                })}
                <TableCell><Button startIcon={<EditIcon />} size="small" color="secondary" onClick={(e) => onEditRowKey(e, row)} variant="outlined">{trans.btn_edit_key}</Button></TableCell>
                <TableCell><Button startIcon={<DeleteIcon />} size="small" color="error" onClick={(e) => onDeleteRow(e, row)} variant="outlined">{trans.btn_delete}</Button></TableCell>                

              </TableRow>
            )}
          />
        </Grid>

      </Paper>
    </Grid>

    <AddTranslation
      open={addModal}
      onClose={onDialogClose}
      onSubmit={() => { onDialogSubmit() }}
      editItem={editItem}  // Pass if you're editing an existing item
      editItemEn={editItemEn}  // Pass if you're editing the English translation
    />

    <EditTranslationKey
      open={editTranslationKeyModal}
      onClose={onDialogTranslationKeyClose}
      onSubmit={() => { onDialogSubmitTranslationKey() }}
      editItem={editItemKey}  // Pass if you're editing an existing item
    />


  </Container>
  );
}

export default Translations;
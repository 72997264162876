import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Button,
  Grid,
  IconButton,
  CircularProgress,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import CloseIcon from "@mui/icons-material/Close";
import SpeciesSelector from "../global/dropdowns/SpeciesSelector";
import LocalKeyField from "../global/textFields/LocalKeyField";
import {
  postDataContentAdd,
  postDataContentEdit,
} from "../../redux/dataContent/action";
import { toast } from "react-toastify";
import SweetAlert from "sweetalert2";
import { PRIMARY_COLOR } from "../../constants";
import { postTranslationsAddAsync } from "../../redux/translations/action";
import { checkIsPublic, getContentTypeItem } from "../../helpers/General";
import {
  POST_DATA_CONTENT_ADD,
  POST_DATA_CONTENT_EDIT,
} from "../../redux/actionTypes";
import MyColorPicker from "../global/colorPicker/MyColorPicker";
import parse from "html-react-parser";
import { DatePicker, DateTimePicker } from "@mui/x-date-pickers";
import { formatISO, parseISO } from "date-fns";

const AddDataContent = ({ open, onClose, editItem, onSubmit, contentType }) => {
  const dispatch = useDispatch();
  const settingsData = useSelector(({ settings }) => settings.settingsData);
  const trans = settingsData.trans;

  const [details, setDetails] = useState("");
  const [name, setName] = useState("");
  const [classification, setClassification] = useState("");
  const [publishedAt, setPublishedAt] = useState(null);
  const [isPublished, setIsPublished] = useState(false);
  const [selectedSpecies, setSelectedSpecies] = useState([]);
  const [submiting, setSubmiting] = useState(false);
  const [detailsChanged, setDetailsChanged] = useState(false);
  const [contentTypeItem, setContentTypeItem] = useState();
  const [bodySystem, setBodySystem] = useState("");
  const [location, setLocation] = useState("");
  const [unit, setUnit] = useState("");
  const [isCommon, setIsCommon] = useState(false);
  const [type, setType] = useState(1.0);
  const [order, setOrder] = useState(1);
  const [icon, setIcon] = useState("");
  const [color, setColor] = useState("#");

  const addDataContentData = useSelector(
    ({ dataContent }) => dataContent.addDataContentData
  );
  const editDataContentData = useSelector(
    ({ dataContent }) => dataContent.editDataContentData
  );

  useEffect(() => {
    if (open) {
      setContentTypeItem(getContentTypeItem(settingsData, contentType));
    }
  }, [open, contentType]);

  useEffect(() => {
    if (editItem) {
      //Common
      setName(editItem.nameLocKey || "");
      setPublishedAt(editItem.publishedAt);
      setIsPublished(checkIsPublic(editItem.publishedAt) == 2)

      //Extra
      setDetails(editItem.detailsLocKey || "");
      setClassification(editItem.classification || "");
      setBodySystem(editItem.bodySystem || "");
      setUnit(editItem.unit || "");
      setLocation(editItem.location || "");
      setType(editItem.type || 1.0);
      setOrder(editItem.order || 1);
      setIcon(decodeURIComponent(editItem.iconSVG) || "");
      setColor(editItem.colorHex || "");
    } else {
      clearData();
    }
  }, [editItem]);

  useEffect(() => {
    // ADD DATA
    if (addDataContentData != null) {
      if (addDataContentData.contentType == contentType) {
        setSubmiting(false);
        if (addDataContentData.data.status) {
          toast.dismiss();
          toast.success(addDataContentData.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          clearData();
          onSubmit();
        } else {
          toast.dismiss();
          toast.error(addDataContentData.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
        dispatch({ type: POST_DATA_CONTENT_ADD, payload: null });
      }
    }
  }, [addDataContentData]);

  useEffect(() => {
    // EDIT DATA
    if (editDataContentData != null) {
      if (editDataContentData.contentType == contentType) {
        setSubmiting(false);
        if (editDataContentData.data.status) {
          toast.dismiss();
          toast.success(editDataContentData.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          clearData();
          onSubmit();
          onClose();
        } else {
          toast.dismiss();
          if (editDataContentData.data.status_code == 422) {
            toast.error(`${trans.key_untranslated_error}`, {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.error(editDataContentData.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        }
        dispatch({ type: POST_DATA_CONTENT_EDIT, payload: null });
      }
    }
  }, [editDataContentData]);

  const clearData = () => {
    setDetails("");
    setName("");
    setClassification("");
    setPublishedAt(null);
    setSelectedSpecies([]);
    setSubmiting(false);
    setDetailsChanged(false);
    setBodySystem("");
    setUnit("");
    setLocation("");
    setType(1.0);
    setOrder(1);
    setIcon("");
    setColor("#");
  };

  const determinePart1 = () => {
    var species = "";
    if (selectedSpecies.length == 1) {
      species = `.${selectedSpecies[0]}`;
    }

    var part1 = contentTypeItem != null ? contentTypeItem.key : "";

    part1 = part1.replace("{{species}}", species);

    return part1;
  };

  const determinePart2 = () => {
    var part2 = "";
    // if (selectedSpecies.length == 1) {
    //   part2 = selectedSpecies[0];
    // }

    return part2;
  };

  const handleSubmit = async () => {
    // Validate name and classification inputs
    if (name === "") {
      toast.error(trans.missing_data_values + " " + "name");
      return;
    }

    // Prepare the postData object
    // const postData = {
    //   nameLocKey: name,
    //   isPublic:
    //     toPublish === true
    //       ? toPublish
    //       : editItem !== null
    //       ? checkIsPublic(editItem) == 2
    //       : false,
    // };

    const postData = {
      nameLocKey: name,
      publishedAt: parseISO(publishedAt).toISOString(),
    };

    if (contentTypeItem.classification) {
      if (classification === "") {
        toast.error(trans.missing_data_values + " " + "classification");
        return;
      }
      postData.classification = classification;
    }

    if (contentTypeItem.details) {
      postData.detailsLocKey = details;
    }

    if (contentTypeItem.body_system == true) {
      if (bodySystem === "") {
        toast.error(trans.invalid_body_system);
        return;
      }
      postData.bodySystem = bodySystem;
    }

    if (contentTypeItem.unit == true) {
      if (unit === "") {
        toast.error(trans.invalid_unit);
        return;
      }
      postData.unit = unit;
    }

    if (contentTypeItem.isCommon == true) {
      postData.isCommon = isCommon;
    }

    if (contentTypeItem.location == true) {
      if (location === "") {
        toast.error(trans.invalid_location);
        return;
      }
      postData.location = location;
    }

    if (contentTypeItem.hasType == true) {
      postData.type = parseFloat(type);
    }

    if (contentTypeItem.hasOrder == true) {
      postData.order = parseInt(order);
    }

    if (contentTypeItem.hasColor == true) {
      if (color === "" || color.length != 7 || !color.includes("#")) {
        toast.error(trans.invalid_color);
        return;
      }
      postData.colorHex = color;
    }

    if (contentTypeItem.hasIcon == true) {
      if (icon === "" || !icon.includes("</svg>") || !icon.includes("<svg")) {
        toast.error(trans.invalid_icon);
        return;
      }
      postData.iconSVG = icon; 
    }

    const isEditMode = editItem !== null;
    const toCreateFromEdit = isEditMode && editItem.nameLocKey !== name;

    const speciesToUpdate =
      isEditMode && editItem.species != null
        ? [editItem.species]
        : selectedSpecies;

    //We need to check if we have to create the translation keys
    setSubmiting(true);
    if (!isEditMode || toCreateFromEdit) {
      const translationNamePostData = {
        locale: "en",
        key: name,
      };

      const resultName = await postTranslationsAddAsync(
        translationNamePostData
      );

      if (!resultName.status) {
        setSubmiting(false);
        toast.dismiss();
        toast.error(`Name ${trans.key_already_exist}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
        return;
      }

      if (contentTypeItem.details) {
        setSubmiting(true);
        const translationDetailsPostData = {
          locale: "en",
          key: details,
        };

        const resultDetails = await postTranslationsAddAsync(
          translationDetailsPostData
        );

        if (!resultDetails || !resultDetails.status) {
          setSubmiting(false);
          toast.dismiss();
          toast.error(`Details ${trans.key_already_exist}`, {
            position: toast.POSITION.TOP_RIGHT,
          });
          return;
        }
      }
    }

    if (!isEditMode) {
      if (speciesToUpdate.length > 0) {
        for (const speciesItem of speciesToUpdate) {
          var speciesUrl = settingsData.species_types.find(
            (s) => s.key == speciesItem.toLowerCase()
          ).url;
          dispatch(
            postDataContentAdd(
              contentTypeItem.endpoint,
              contentTypeItem.type,
              speciesUrl,
              postData
            )
          );
        }
      } else {
        dispatch(
          postDataContentAdd(
            contentTypeItem.endpoint,
            contentTypeItem.type,
            "",
            postData
          )
        );
      }
    } else {
      dispatch(
        postDataContentEdit(
          contentTypeItem.endpoint,
          contentTypeItem.type,
          editItem.species != null ? editItem.species.toLowerCase() + "s" : "",
          postData,
          editItem.id
        )
      );
    }
  };

  const handlePublish = () => {
    SweetAlert.fire({
      theme: "dark",
      title: trans.edit_top_publish_title,
      text: trans.edit_top_publish_desc,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: PRIMARY_COLOR,
      confirmButtonText: trans.edit_top_publish_btn,
      cancelButtonText: trans.cancel_btn,
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        handleSubmit(true);
      }
    });
  };

  const handleNameChange = (value) => {
    setName(value);
    if (detailsChanged == false) {
      setDetails(`${value}.details`);
    }
  };

  const handleDetailsChange = (value) => {
    setDetails(value);
    if (value.replace(".details", "") != name) {
      setDetailsChanged(true);
    }
  };

  const closeModal = (event, reason) => {
    if (reason && reason == "backdropClick" && "escapeKeyDown") {
      return;
    }
    clearData();
    onClose();
  };

  const handleTypeChange = (e) => {
    const value = e.target.value;
    if (parseFloat(value) < 0.0) {
    } else {
      setType(value);
    }
  };

  const handleTypeBlur = () => {
    // On blur, if the number doesn't contain a decimal, append ".0"
    if (type !== "" && !type.includes(".")) {
      setType(`${type}.0`);
    }
  };

  const handleOrderChange = (e) => {
    const value = e.target.value;
    if (parseInt(value) < 0) {
    } else {
      setOrder(value);
    }
  };

  const handleSaveButton = () => {
    if (publishedAt == null){
      handleSubmit();
    } else {
      if (!isPublished && checkIsPublic(publishedAt) != 1){
        handlePublish();
      } else {
        handleSubmit();
      }
    }
  }

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={closeModal}
      maxWidth="sm"
      onKeyDown={(event) => {
        if (event.key === "Enter" && event.target.id != "species-selector") {
          event.preventDefault();
          handleSubmit();
        }
      }}
    >
      <DialogTitle
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <span>
          {editItem ? trans.edit_item_title : trans.add_item_title}
          {` ${contentTypeItem != null ? trans[contentTypeItem.name] : ""}`}
        </span>

        <IconButton onClick={closeModal}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {editItem ? trans.edit_item_desc : trans.add_item_desc}
        </DialogContentText>

        <Grid container style={{ marginTop: "5px" }} spacing={3}>
          {!editItem &&
          contentTypeItem != null &&
          contentTypeItem.hasSpecies ? (
            <Grid item xs={12}>
              <SpeciesSelector
                multiSelect={
                  contentTypeItem != null ? contentTypeItem.multiSpecies : false
                }
                selectedSpecies={selectedSpecies}
                onChange={(value) => setSelectedSpecies(value)}
              />
            </Grid>
          ) : null}

          {!editItem &&
          contentTypeItem != null &&
          contentTypeItem.body_system ? (
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="body_system_label">
                  {trans.table_body_sytem}
                </InputLabel>
                <Select
                  labelId="body_system_label"
                  id="body_system"
                  value={bodySystem}
                  label={trans.table_body_sytem}
                  onChange={(e) => setBodySystem(e.target.value)}
                >
                  {settingsData.body_systems.map((element) => (
                    <MenuItem key={element.type} value={element.type}>
                      {element.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          ) : null}

          {!editItem && contentTypeItem != null && contentTypeItem.location ? (
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="location_label">
                  {trans.table_location}
                </InputLabel>
                <Select
                  labelId="location_label"
                  id="location"
                  value={location}
                  label={trans.table_location}
                  onChange={(e) => setLocation(e.target.value)}
                >
                  {settingsData.locations.map((element) => (
                    <MenuItem key={element.type} value={element.type}>
                      {element.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          ) : null}

          {contentTypeItem != null && contentTypeItem.unit ? (
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="unit_label">{trans.table_unit}</InputLabel>
                <Select
                  labelId="unit_label"
                  id="unit"
                  value={unit}
                  label={trans.table_unit}
                  onChange={(e) => setUnit(e.target.value)}
                >
                  {settingsData.units.map((element) => (
                    <MenuItem key={element.type} value={element.type}>
                      {element.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          ) : null}

          {contentTypeItem != null && contentTypeItem.classification ? (
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="classification_label">
                  {trans.table_vaccine_classification}
                </InputLabel>
                <Select
                  labelId="classification_label"
                  id="classification"
                  value={classification}
                  label={trans.table_vaccine_classification}
                  disabled={editItem != null}
                  onChange={(e) => setClassification(e.target.value)}
                >
                  <MenuItem value={"core"}>{trans.table_item_core}</MenuItem>
                  <MenuItem value={"nonCore"}>
                    {trans.table_item_non_core}
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
          ) : null}

          {contentTypeItem != null && contentTypeItem.hasType ? (
            <Grid item xs={6}>
              <TextField
                id={`text_field_type`}
                label={`${trans.text_field_type}`}
                value={type}
                onChange={(e) => {
                  handleTypeChange(e);
                }}
                fullWidth
                type="number"
                variant="standard"
                inputProps={{ step: ".1", min: 1 }}
                onBlur={handleTypeBlur}
              />
            </Grid>
          ) : null}

          {contentTypeItem != null && contentTypeItem.hasOrder ? (
            <Grid item xs={6}>
              <TextField
                id={`text_field_order`}
                label={`${trans.text_field_order}`}
                value={order}
                onChange={(e) => {
                  handleOrderChange(e);
                }}
                fullWidth
                type="number"
                variant="standard"
                inputProps={{ min: 1 }}
              />
            </Grid>
          ) : null}

          {contentTypeItem != null && contentTypeItem.hasIcon ? (
            <Grid item xs={1}>
              <div
                style={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  width={30}
                  height={30}
                  src={`data:image/svg+xml;utf8,${
                    icon !== "" && icon.includes("svg") && icon != "icon_svg"
                      ? encodeURIComponent(icon)
                      : encodeURIComponent(
                          '<svg width="800px" height="800px" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="120" height="120" fill="#EFF1F3"/><path fill-rule="evenodd" clip-rule="evenodd" d="M33.2503 38.4816C33.2603 37.0472 34.4199 35.8864 35.8543 35.875H83.1463C84.5848 35.875 85.7503 37.0431 85.7503 38.4816V80.5184C85.7403 81.9528 84.5807 83.1136 83.1463 83.125H35.8543C34.4158 83.1236 33.2503 81.957 33.2503 80.5184V38.4816ZM80.5006 41.1251H38.5006V77.8751L62.8921 53.4783C63.9172 52.4536 65.5788 52.4536 66.6039 53.4783L80.5006 67.4013V41.1251ZM43.75 51.6249C43.75 54.5244 46.1005 56.8749 49 56.8749C51.8995 56.8749 54.25 54.5244 54.25 51.6249C54.25 48.7254 51.8995 46.3749 49 46.3749C46.1005 46.3749 43.75 48.7254 43.75 51.6249Z" fill="#687787"/></svg>'
                        )
                  }`}
                />
              </div>
            </Grid>
          ) : null}

          {contentTypeItem != null && contentTypeItem.hasIcon ? (
            <Grid item xs={11}>
              <TextField
                id={`text_field_icon`}
                label={`${trans.text_field_icon}`}
                value={icon}
                onChange={(e) => {
                  setIcon(e.target.value);
                }}
                fullWidth
                type="text"
                variant="standard"
              />
            </Grid>
          ) : null}

          {contentTypeItem != null && contentTypeItem.hasColor ? (
            <Grid item xs={6}>
              <MyColorPicker
                labelText={trans.data_content_color}
                color={color}
                onChangeColor={(color) => {
                  setColor(color);
                }}
              />
            </Grid>
          ) : null}

          <Grid item xs={12}>
            <LocalKeyField
              fieldType={trans.text_field_name}
              part1={determinePart1()}
              part2={determinePart2()}
              fieldItemKey={name}
              onChange={(value) => {
                handleNameChange(value);
              }}
            />
          </Grid>

          {contentTypeItem != null && contentTypeItem.details ? (
            <Grid item xs={12}>
              <LocalKeyField
                fieldType={trans.text_field_details}
                part1={determinePart1()}
                part2={determinePart2()}
                fieldItemKey={details}
                autofillKey={true}
                onChange={(value) => {
                  handleDetailsChange(value);
                }}
              />
            </Grid>
          ) : null}

          {contentTypeItem != null && contentTypeItem.isCommon ? (
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isCommon}
                    onChange={(e) => setIsCommon(e.target.checked)}
                    name="isCommon"
                    color="primary"
                  />
                }
                label={trans.is_common_label}
              />
            </Grid>
          ) : null}

          {editItem && !isPublished ? (
            <Grid item xs={12}>
              {/* <DateTimePicker /> */}
              <DateTimePicker
                style={{
                  colorScheme: "dark",
                  scrollbarWidth: "none",
                }}
                label={trans.published_at_field}
                type="datetime-local"
                value={
                  publishedAt
                    ? parseISO(publishedAt)
                    : null
                }
                slotProps={{ 
                  textField: { fullWidth: true } ,
                  layout: {
                    sx: {
                      ul: {
                        '::-webkit-scrollbar': {
                          width: '2px',
                        },
                      },
                    },
                  }
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(date) => {
                  const isoDate = date
                    ? formatISO(date)
                    : null;
                  setPublishedAt(isoDate);
                }}
              />
            </Grid>
          ) : null}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeModal} color="secondary">
          {trans.cancel_btn}
        </Button>
        <Button
          onClick={() => {
            handleSaveButton(false);
          }}
          color="primary"
          disabled={submiting}
        >
          {submiting ? <CircularProgress size={24} /> : trans.submit_btn}{" "}
          {/* Show loader if loading */}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddDataContent;

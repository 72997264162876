import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import {useDispatch, useSelector} from "react-redux";
import { Paper } from '@mui/material';
import CustomTitle from '../global/CustomTitle';

const Dashboard = () => {

const dispatch = useDispatch();
const settingsData = useSelector(({settings}) => settings.settingsData);
const signInData = useSelector(({auth}) => auth.signInData);
const userInfoData = useSelector(({auth}) => auth.userInfoData);
const [loading,setLoading] = useState(false);
const trans = settingsData.trans;



  return (<Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
  <Grid container spacing={1}>
    <Grid item xs={12}>
      <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
        <CustomTitle>{trans.menu_dashboard}</CustomTitle>
      </Paper>
    </Grid>

    <Grid item xs={12}>
      <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
        <p>{userInfoData.givenName}, {trans.dashboard_welcome}</p>
      </Paper>
    </Grid>
    
    
  </Grid>      

</Container>);
}

export default Dashboard;
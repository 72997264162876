import React, { useState,useEffect } from 'react';
import { Navigate} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import { getConfigurations, getSettings } from '../redux/settings/action';
import Loading from './loading';
import * as General from '../helpers/General'
import { postUserInfo } from '../redux/auth/action';
import { useNavigate } from 'react-router';
import { DEBUG_MODE } from '../constants';
import { postTranslationsAll } from '../redux/translations/action';

const PrivateRoute = (props) => {


    const dispatch = useDispatch();
    let navigate = useNavigate();

    //States
    const [settingsLoaded,setSettingsLoaded] = useState(false);
    const [userDataLoaded,setUserDataLoaded] = useState(false);
    const [userAuth,setUserAuth] = useState(false);

    //Selectors
    const userInfoData = useSelector(({auth}) => auth.userInfoData);
    const settingsData = useSelector(({settings}) => settings.settingsData);
    const translationsData = useSelector(({translations}) => translations.translationsData);
    const configurationData = useSelector(({settings}) => settings.configurationData);

    useEffect(() => { 
      if (DEBUG_MODE){
        console.log("PRIVATE_ROUTE");
      }
      //On appliction load we always get the settings first.
      setSettingsLoaded(false);
      setUserDataLoaded(false);
      setUserAuth(false);
      getSettingsData();
    }, []);

    useEffect(() => {
      if (settingsData != null){
        //Settings data loaded
        setSettingsLoaded(true); 
        
        //We check if the user has logged in already to get the user data 
        //Otherwise we will let the user to login
        if (General.isLoggedIn()){
          // getUserData();
          getConfigurationsData();
        }  else {
          setUserDataLoaded(true);
          setUserAuth(false);
        }

      }
    }, [settingsData]);

    useEffect(() => {
      if (configurationData != null){
        if (configurationData.status != null && configurationData.status == false){
          getTranslationsData();
        } else {
          getTranslationsData();
        }
      }
    }, [configurationData]);


    useEffect(() => {
      if (translationsData != null){
          getUserData();
       }
    }, [translationsData]);

    useEffect(() => {
      if (userInfoData != null) {
        if (props.path == "/login") {
          window.location.replace('/admin');
        }
        setUserDataLoaded(true); 
        setUserAuth(true);
      } 
    }, [userInfoData]);

    const getSettingsData = () => {
      dispatch(getSettings()); 
    }

    const getConfigurationsData = () => {
      dispatch(getConfigurations()); 
    }

    const getTranslationsData = () => {
      dispatch(postTranslationsAll({})); 
    }

    const getUserData = () => {
      dispatch(postUserInfo());
    }

    if (!settingsLoaded) {
      return <Loading/>;
    } else {
     if (!userDataLoaded){
        return <Loading/>;
     } else {
      if (userAuth == true){
        return props.children;
      } else {
       if (props.path != "/login"){
          return (<Navigate to="/login"/>);
        } else {
          return props.children;
        }
      }
    
    }
  }
   
}

export default PrivateRoute;